
import http from "@/utils/request";
export function queryOrg(data) {
    return http({
        method: "get",
        url: "/comm/queryOrg.nd",
        data
    });
}

export function saveMineQuestion(data) {
    return http({
        method: "post",
        url: "/question/saveMineQuestion.nd",
        data
    });
}


export function getMGroup(data) {
    return http({
      method: "get",
      url: "/question/setMaterialCode.nd?orgId=" + data,
    });
  }

//   举报筛选条件

export function getjuBao() {
    return http({
      method: "get",
      url: "/question/commitComplaint.nd",
    });
  }